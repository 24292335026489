<template>
    <div class="danger-restaurant">
        <router-link class="danger-restaurant__link danger-restaurant__link_back link link_red"
            ref="backLink"
            :to="{ name: 'DangerTotal' }"
        >
            Вернуться к общему отчету по опасным операциям
        </router-link>
        <section class="danger-restaurant__settings">
            <h2 class="sr-only">Настройки отчета "Опасные операции ресторана"</h2>
            <form class="danger-total__controls" @submit.prevent>
                <div class="danger-total__dateranges">
                    <daterange-picker class="danger-total__daterange"
                        v-model="reportDaterangeModel"
                        name="reportDaterange"
                        :minDate="minDate"
                    >
                        Период отчета:
                    </daterange-picker>
                </div>
                <danger-settings class="danger-total__settings"  v-if="isShowRestaurantsFilter" />
            </form>
        </section>
        <spinner class="danger-restaurant__spinner" v-if="status === 'loading'" />
        <error-message class="danger-restaurant__error-message" v-else-if="status === 'error'">
            Не удалось загрузить данные отчета "Опасные операции ресторана".
        </error-message>
        <section class="danger-restaurant__data" v-else-if="status === 'success'">
            <div class="danger-restaurant__base-details">
                <div class="danger-restaurant__box box">
                    <table class="danger-restaurant__table danger-restaurant__table_striped danger-restaurant__table_hover">
                        <col class="danger-restaurant__table-column danger-restaurant__table-column_details-date">
                        <col class="danger-restaurant__table-column danger-restaurant__table-column_details-event">
                        <col class="danger-restaurant__table-column danger-restaurant__table-column_details-user">
                        <col class="danger-restaurant__table-column danger-restaurant__table-column_details-user-auth">
                        <col class="danger-restaurant__table-column danger-restaurant__table-column_details-waiter">
                        <col class="danger-restaurant__table-column danger-restaurant__table-column_details-session_num">
                        <col class="danger-restaurant__table-column danger-restaurant__table-column_details-order_num">
                        <col class="danger-restaurant__table-column danger-restaurant__table-column_details-table_num">
                        <col class="danger-restaurant__table-column danger-restaurant__table-column_details-guests_num">
                        <col class="danger-restaurant__table-column danger-restaurant__table-column_details-row_count">
                        <col class="danger-restaurant__table-column danger-restaurant__table-column_details-sum">
                        <col class="danger-restaurant__table-column danger-restaurant__table-column_details-dishes">
                        <col class="danger-restaurant__table-column danger-restaurant__table-column_details-reason">
                        <col class="danger-restaurant__table-column danger-restaurant__table-column_details-comment">
                        <thead>
                            <tr class="danger-restaurant__table-row danger-restaurant__table-row_head">
                                <th class="danger-restaurant__table-cell danger-restaurant__table-cell_text-small danger-restaurant__table-cell_head danger-restaurant__table-cell_sortable"
                                    scope="col"
                                    @click="setDangerDetailSorting('date')"
                                >
                                    <span class="danger-restaurant__table-text">Дата</span>
                                    <svg-icon class="danger-restaurant__table-icon"
                                        v-if="sorting.field === 'date' && sorting.direction === 'ASC'"
                                        icon="chevron-up"
                                    />
                                    <svg-icon class="danger-restaurant__table-icon"
                                        v-if="sorting.field === 'date' && sorting.direction === 'DESC'"
                                        icon="chevron-down"
                                    />
                                </th>
                                <th class="danger-restaurant__table-cell danger-restaurant__table-cell_text-small danger-restaurant__table-cell_head danger-restaurant__table-cell_sortable"
                                    scope="col"
                                    @click="setDangerDetailSorting('event')"
                                >
                                    <span class="danger-restaurant__table-text">Событие</span>
                                    <svg-icon class="danger-restaurant__table-icon"
                                        v-if="sorting.field === 'event' && sorting.direction === 'ASC'"
                                        icon="chevron-up"
                                    />
                                    <svg-icon class="danger-restaurant__table-icon"
                                        v-if="sorting.field === 'event' && sorting.direction === 'DESC'"
                                        icon="chevron-down"
                                    />
                                </th>
                                <th class="danger-restaurant__table-cell danger-restaurant__table-cell_text-small danger-restaurant__table-cell_head danger-restaurant__table-cell_sortable"
                                    scope="col"
                                    @click="setDangerDetailSorting('user')"
                                >
                                    <span class="danger-restaurant__table-text">Пользователь</span>
                                    <svg-icon class="danger-restaurant__table-icon"
                                        v-if="sorting.field === 'user' && sorting.direction === 'ASC'"
                                        icon="chevron-up"
                                    />
                                    <svg-icon class="danger-restaurant__table-icon"
                                        v-if="sorting.field === 'user' && sorting.direction === 'DESC'"
                                        icon="chevron-down"
                                    />
                                </th>
                                <th class="danger-restaurant__table-cell danger-restaurant__table-cell_text-small danger-restaurant__table-cell_head danger-restaurant__table-cell_sortable"
                                    scope="col"
                                    @click="setDangerDetailSorting('user_auth')"
                                >
                                    <span class="danger-restaurant__table-text">Доп. авторизация</span>
                                    <svg-icon class="danger-restaurant__table-icon"
                                        v-if="sorting.field === 'user_auth' && sorting.direction === 'ASC'"
                                        icon="chevron-up"
                                    />
                                    <svg-icon class="danger-restaurant__table-icon"
                                        v-if="sorting.field === 'user_auth' && sorting.direction === 'DESC'"
                                        icon="chevron-down"
                                    />
                                </th>
                                <th class="danger-restaurant__table-cell danger-restaurant__table-cell_text-small danger-restaurant__table-cell_head danger-restaurant__table-cell_sortable"
                                    scope="col"
                                    @click="setDangerDetailSorting('waiter')"
                                >
                                    <span class="danger-restaurant__table-text">Официант</span>
                                    <svg-icon class="danger-restaurant__table-icon"
                                        v-if="sorting.field === 'waiter' && sorting.direction === 'ASC'"
                                        icon="chevron-up"
                                    />
                                    <svg-icon class="danger-restaurant__table-icon"
                                        v-if="sorting.field === 'waiter' && sorting.direction === 'DESC'"
                                        icon="chevron-down"
                                    />
                                </th>
                                <th class="danger-restaurant__table-cell danger-restaurant__table-cell_text-small danger-restaurant__table-cell_tar danger-restaurant__table-cell_head danger-restaurant__table-cell_sortable"
                                    scope="col"
                                    @click="setDangerDetailSorting('session_num')"
                                >
                                    <span class="danger-restaurant__table-text">№ смены</span>
                                    <svg-icon class="danger-restaurant__table-icon"
                                        v-if="sorting.field === 'session_num' && sorting.direction === 'ASC'"
                                        icon="chevron-up"
                                    />
                                    <svg-icon class="danger-restaurant__table-icon"
                                        v-if="sorting.field === 'session_num' && sorting.direction === 'DESC'"
                                        icon="chevron-down"
                                    />
                                </th>
                                <th class="danger-restaurant__table-cell danger-restaurant__table-cell_text-small danger-restaurant__table-cell_tar danger-restaurant__table-cell_head danger-restaurant__table-cell_sortable"
                                    scope="col"
                                    @click="setDangerDetailSorting('order_num')"
                                >
                                    <span class="danger-restaurant__table-text">№ заказа</span>
                                    <svg-icon class="danger-restaurant__table-icon"
                                        v-if="sorting.field === 'order_num' && sorting.direction === 'ASC'"
                                        icon="chevron-up"
                                    />
                                    <svg-icon class="danger-restaurant__table-icon"
                                        v-if="sorting.field === 'order_num' && sorting.direction === 'DESC'"
                                        icon="chevron-down"
                                    />
                                </th>
                                <th class="danger-restaurant__table-cell danger-restaurant__table-cell_text-small danger-restaurant__table-cell_tar danger-restaurant__table-cell_head danger-restaurant__table-cell_sortable"
                                    scope="col"
                                    @click="setDangerDetailSorting('table_num')"
                                >
                                    <span class="danger-restaurant__table-text">№ стола</span>
                                    <svg-icon class="danger-restaurant__table-icon"
                                        v-if="sorting.field === 'table_num' && sorting.direction === 'ASC'"
                                        icon="chevron-up"
                                    />
                                    <svg-icon class="danger-restaurant__table-icon"
                                        v-if="sorting.field === 'table_num' && sorting.direction === 'DESC'"
                                        icon="chevron-down"
                                    />
                                </th>
                                <th class="danger-restaurant__table-cell danger-restaurant__table-cell_text-small danger-restaurant__table-cell_tar danger-restaurant__table-cell_head danger-restaurant__table-cell_sortable"
                                    scope="col"
                                    @click="setDangerDetailSorting('guests_num')"
                                >
                                    <span class="danger-restaurant__table-text">Кол-во гостей</span>
                                    <svg-icon class="danger-restaurant__table-icon"
                                        v-if="sorting.field === 'guests_num' && sorting.direction === 'ASC'"
                                        icon="chevron-up"
                                    />
                                    <svg-icon class="danger-restaurant__table-icon"
                                        v-if="sorting.field === 'guests_num' && sorting.direction === 'DESC'"
                                        icon="chevron-down"
                                    />
                                </th>
                                <th class="danger-restaurant__table-cell danger-restaurant__table-cell_text-small danger-restaurant__table-cell_tar danger-restaurant__table-cell_head danger-restaurant__table-cell_sortable"
                                    scope="col"
                                    @click="setDangerDetailSorting('row_count')"
                                >
                                    <span class="danger-restaurant__table-text">Кол-во блюд</span>
                                    <svg-icon class="danger-restaurant__table-icon"
                                        v-if="sorting.field === 'row_count' && sorting.direction === 'ASC'"
                                        icon="chevron-up"
                                    />
                                    <svg-icon class="danger-restaurant__table-icon"
                                        v-if="sorting.field === 'row_count' && sorting.direction === 'DESC'"
                                        icon="chevron-down"
                                    />
                                </th>
                                <th class="danger-restaurant__table-cell danger-restaurant__table-cell_text-small danger-restaurant__table-cell_tar danger-restaurant__table-cell_head danger-restaurant__table-cell_sortable"
                                    scope="col"
                                    @click="setDangerDetailSorting('sum')"
                                >
                                    <span class="danger-restaurant__table-text">Сумма</span>
                                    <svg-icon class="danger-restaurant__table-icon"
                                        v-if="sorting.field === 'sum' && sorting.direction === 'ASC'"
                                        icon="chevron-up"
                                    />
                                    <svg-icon class="danger-restaurant__table-icon"
                                        v-if="sorting.field === 'sum' && sorting.direction === 'DESC'"
                                        icon="chevron-down"
                                    />
                                </th>
                                <th class="danger-restaurant__table-cell danger-restaurant__table-cell_text-small danger-restaurant__table-cell_head danger-restaurant__table-cell_sortable"
                                    scope="col"
                                    @click="setDangerDetailSorting('dishes')"
                                >
                                    <span class="danger-restaurant__table-text">Блюда</span>
                                    <svg-icon class="danger-restaurant__table-icon"
                                        v-if="sorting.field === 'dishes' && sorting.direction === 'ASC'"
                                        icon="chevron-up"
                                    />
                                    <svg-icon class="danger-restaurant__table-icon"
                                        v-if="sorting.field === 'dishes' && sorting.direction === 'DESC'"
                                        icon="chevron-down"
                                    />
                                </th>
                                <th class="danger-restaurant__table-cell danger-restaurant__table-cell_text-small danger-restaurant__table-cell_head danger-restaurant__table-cell_sortable"
                                    scope="col"
                                    @click="setDangerDetailSorting('reason')"
                                >
                                    <span class="danger-restaurant__table-text">Причина</span>
                                    <svg-icon class="danger-restaurant__table-icon"
                                        v-if="sorting.field === 'reason' && sorting.direction === 'ASC'"
                                        icon="chevron-up"
                                    />
                                    <svg-icon class="danger-restaurant__table-icon"
                                        v-if="sorting.field === 'reason' && sorting.direction === 'DESC'"
                                        icon="chevron-down"
                                    />
                                </th>
                                <th class="danger-restaurant__table-cell danger-restaurant__table-cell_text-small danger-restaurant__table-cell_head danger-restaurant__table-cell_sortable"
                                    scope="col"
                                    @click="setDangerDetailSorting('comment')"
                                >
                                    <span class="danger-restaurant__table-text">Комментарий</span>
                                    <svg-icon class="danger-restaurant__table-icon"
                                        v-if="sorting.field === 'comment' && sorting.direction === 'ASC'"
                                        icon="chevron-up"
                                    />
                                    <svg-icon class="danger-restaurant__table-icon"
                                        v-if="sorting.field === 'comment' && sorting.direction === 'DESC'"
                                        icon="chevron-down"
                                    />
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr class="danger-restaurant__table-row danger-restaurant__table-row_body"
                                v-for="detail in reportDetails"
                                :key="detail.id"
                            >
                                <td class="danger-restaurant__table-cell danger-restaurant__table-cell_text-medium">
                                    {{detail.date | datetimeText}}
                                </td>
                                <td class="danger-restaurant__table-cell danger-restaurant__table-cell_text-medium">
                                    {{detail.event}}
                                </td>
                                <td class="danger-restaurant__table-cell danger-restaurant__table-cell_text-medium">
                                    {{detail.user}}
                                </td>
                                <td class="danger-restaurant__table-cell danger-restaurant__table-cell_text-medium">
                                    {{detail.user_auth}}
                                </td>
                                <td class="danger-restaurant__table-cell danger-restaurant__table-cell_text-medium">
                                    {{detail.waiter}}
                                </td>
                                <td class="danger-restaurant__table-cell danger-restaurant__table-cell_text-medium danger-restaurant__table-cell_tar">
                                    {{detail.session_num | intFormat}}
                                </td>
                                <td class="danger-restaurant__table-cell danger-restaurant__table-cell_text-medium danger-restaurant__table-cell_tar">
                                    {{detail.order_num | intFormat}}
                                </td>
                                <td class="danger-restaurant__table-cell danger-restaurant__table-cell_text-medium danger-restaurant__table-cell_tar">
                                    {{detail.table_num | intFormat}}
                                </td>
                                <td class="danger-restaurant__table-cell danger-restaurant__table-cell_text-medium danger-restaurant__table-cell_tar">
                                    {{detail.guests_num | intFormat}}
                                </td>
                                <td class="danger-restaurant__table-cell danger-restaurant__table-cell_text-medium danger-restaurant__table-cell_tar">
                                    {{detail.row_count | intFormat}}
                                </td>
                                <td class="danger-restaurant__table-cell danger-restaurant__table-cell_text-medium danger-restaurant__table-cell_tar">
                                    {{detail.sum | currencyIntFormat}}
                                </td>
                                <td class="danger-restaurant__table-cell danger-restaurant__table-cell_text-medium">
                                    {{detail.dishes}}
                                </td>
                                <td class="danger-restaurant__table-cell danger-restaurant__table-cell_text-medium">
                                    {{detail.reason}}
                                </td>
                                <td class="danger-restaurant__table-cell danger-restaurant__table-cell_text-medium">
                                    {{detail.comment}}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
    import { mapActions, mapMutations, mapState } from "vuex";
    import parseISO from "date-fns/parseISO";
    import { daterangeKey,  datetimeText } from "@/helpers/daterange";
    import { compareStrings, compareNumbers, compareDates } from "@/helpers/compare";
    import DaterangePicker from "@/components/DaterangePicker";
    import DangerSettings from "./common/DangerSettings";

    let totalTitle = "Опасные операций всех ресторанов";

    export default{
        name: "DangerRestaurant",
        components: {
            DangerSettings,
            DaterangePicker,
        },
        props: {
            id: {
                type: String,
                required: true
            },
            reportDaterange: {
                type: Object,
                required: true
            },
            userRestaurants: {
                required: true
            },
            reportRestaurants: {
                required: true
            },
            minDate: {
                type: Date,
                required: true
            },
            isShowRestaurantsFilter: {
                type: Boolean,
                required: true
            },
            reportRestaurantsKey: {
                required: true
            },
            reportKey: {
                required: true
            },
        },
        data() {
            return {
                status: "loading"
            };
        },
        computed: {
            ...mapState({
                title: state => state.page.title,
                sorting: state => state.danger.sorting.details,
                data: state => state.danger.data
            }),
            reportDaterangeModel: {
                get() {
                    return this.reportDaterange;
                },
                set(daterange) {
                    this.setReportDaterange(daterange);
                }
            },
            requiredDateranges() {
                return [this.reportDaterange];
            },
            requiredDangers() {
                return this.requiredDateranges?.map(daterange => this.data[`${daterangeKey(daterange)}-${this.reportRestaurantsKey}`]);
            },
            reportDanger() {
                return this.data?.[this.reportKey];
            },
            reportRestaurantThis() {
                if (this.id === "total") {
                    return this.reportDanger?.total;
                }

                return this.reportDanger?.restaurants?.find(({ id }) => id === this.id);
            },
            reportDetails() {
                let details;

                if (this.id === "total") {
                    details = [];

                    this.reportDanger?.restaurants.forEach(({ detail }) => {
                        details.push(...detail);
                    });
                } else {
                    details = this.reportRestaurantThis?.detail;
                }

                return details.map(detail => {
                    if (!(detail.date instanceof Date)) {
                        detail.date = parseISO(detail.date);
                    }

                    return detail;
                })?.sort((detail1, detail2) => {
                    let compareFn;

                    if (this.sorting.field === "date") {
                        compareFn = compareDates;
                    } else if (
                        ["event", "user", "user_auth", "waiter", "dishes", "reason", "comment"].includes(this.sorting.field)
                    ) {
                        compareFn = compareStrings;
                    } else {
                        compareFn = compareNumbers;
                    }

                    return compareFn(
                        detail1[this.sorting.field],
                        detail2[this.sorting.field],
                        this.sorting.direction
                    );
                });
            },
        },
        methods: {
            ...mapMutations([
                "setTitle",
                "setReportDaterange",
                "setDangerParameter",
                "setDangerDetailSorting",
                "toggleRestaurantsFilter"
            ]),
            ...mapActions([
                "requestDangers"
            ]),
            findRestaurant(danger, restaurantId) {
                return danger?.restaurants?.find(({ id }) => id === restaurantId);
            },
            findEvent(events, eventId) {
                return events?.find(({ id }) => id === eventId);
            },
            showReport() {
                this.status = "success";
            },
            loadDangers() {
                this.status = "loading";

                let daterangesWithoutData = this.requiredDateranges.filter(
                    daterange => !Boolean(this.data[`${daterangeKey(daterange)}-${this.reportRestaurantsKey}`])
                );

                this.requestDangers({
                    dateranges: daterangesWithoutData,
                    restaurants: this.reportRestaurants,
                    restaurantsKey: this.reportRestaurantsKey,
                }).then(() => {
                    this.showReport();
                }).catch(() => {
                    this.status = "error";
                });
            },
        },
        filters: {
            datetimeText
        },
        created() {
            if (this.reportRestaurants.length === 0) {
                this.status = "no-restaurants";
            } else if (this.id !== "total" && !this.userRestaurants?.ids?.includes(this.id)) {
                this.$router.push({ name: "Error401" });
            } else {

                if (this.id === "total") {
                    this.toggleRestaurantsFilter(true);
                }

                if (this.id === "total" && this.title !== totalTitle) {
                    this.setTitle(totalTitle);
                } else {
                    let restaurantTitle = this.userRestaurants?.byId?.[this.id]?.title;

                    if (restaurantTitle && !this.title.includes(restaurantTitle)) {
                        this.setTitle(`${this.title} "${restaurantTitle}"`);
                    }

                    this.toggleRestaurantsFilter(false);
                }

                if (this.requiredDangers.every(Boolean)) {
                    this.showReport();
                } else {
                    this.loadDangers();
                }
            }
        },
        watch: {
            requiredDangers(requiredDangers) {
                if (this.reportRestaurants.length === 0) {
                    this.status = "no-restaurants";
                } else if (!requiredDangers.every(Boolean)) {
                    this.loadDangers();
                }
            }
        }
    }
</script>

<style lang="scss">
    .danger-restaurant__link {
        &_back {
            display: inline-block;
            margin-bottom: 5px;

            @include desktop {
                margin-bottom: 10px;
            }
        }
    }
    .danger-restaurant__settings {
        margin-bottom: 15px;
    }
    .danger-restaurant__dateranges {
        display: grid;
        grid-auto-columns: auto;
        grid-auto-rows: auto;
        grid-gap: 10px;
        justify-content: start;
        align-content: start;
        justify-items: start;
        align-items: start;
        margin-bottom: 15px;

        @include desktop {
            grid-auto-flow: column;
        }
    }
    .danger-restaurant__toggles {
        display: grid;
        grid-auto-columns: auto;
        grid-auto-rows: auto;
        grid-gap: 10px;
        justify-content: start;
        align-content: start;
        justify-items: start;
        align-items: start;

        @include desktop {
            grid-auto-flow: column;
        }
    }
    .danger-restaurant__select {
        min-width: 240px;
        padding: 4px 8px;
        margin-top: 15px;
        border-color: $gray-line;
        border-radius: 4px;

        &:focus {
            outline: none;
        }
    }
    .danger-restaurant__box {
        padding: 16px;
        overflow-x: auto;

        &::-webkit-scrollbar {
            width: 8px;
            height: 8px;
            background-color: $gray-line;
        }
        &::-webkit-scrollbar-track {
            border-width: 0;
        }
        &::-webkit-scrollbar-thumb {
            background-color: $text-gray-secondary;
        }

        & + & {
            margin-top: 15px;
        }
    }
    .danger-restaurant__table {
        width: 100%;
    }
    .danger-restaurant__table-caption {
        padding: 8px;
        font-size: 24px;
        font-weight: bold;
    }
    .danger-restaurant__table-column {
        &_events-event {
            width: 25%;
        }
        &_events-count {
            width: 15%;
        }

        &_details-date {
            min-width: 180px;
        }
        &_details-event {
            min-width: 150px;
        }
        &_details-user {
            min-width: 150px;
        }
        &_details-user-auth {
            min-width: 150px;
        }
        &_details-waiter {
            min-width: 150px;
        }
        &_details-session_num {
            min-width: 100px;
        }
        &_details-order_num {
            min-width: 100px;
        }
        &_details-table_num {
            min-width: 100px;
        }
        &_details-guests_num {
            min-width: 100px;
        }
        &_details-row_count {
            min-width: 100px;
        }
        &_details-sum {
            min-width: 100px;
        }
        &_details-dishes {
            min-width: 250px;
        }
        &_details-reason {
            min-width: 150px;
        }
        &_details-comment {
            min-width: 150px;
        }

        &_compare-event-daterange {
            width: 20%;
        }
        &_compare-event-count {
            width: 15%;
        }
    }
    .danger-restaurant__table-row {
        &_body {
            .danger-restaurant__table_striped &:nth-of-type(odd) {
                background-color: $background-gray-primary;
            }

            .danger-restaurant__table_hover &:hover {
                background-color: $gray-form;
            }
        }

        &_pinned {
            background-color: $gray-form !important;
        }
    }
    .danger-restaurant__table-cell {
        font-size: 20px;
        padding: 16px 8px;

        &_head {
            white-space: nowrap;
            width: 250px;
        }

        &_sortable {
            cursor: pointer;
        }

        &_title {
            font-weight: bold;
        }

        &_text-small {
            font-size: 14px;
        }

        &_text-medium {
            font-size: 16px;
        }

        &_tar {
            text-align: right;
        }
    }
    .danger-restaurant__table-value {
        line-height: 1;
        vertical-align: middle;

        & + & {
            margin-left: 5px;
        }

        &_icon {
            width: 24px;
            height: 24px;
        }

        &_positive {
            color: green;
        }

        &_negative {
            color: red;
        }
    }
    .danger-restaurant__table-icon {
        width: 16px;
        height: 16px;
        vertical-align: middle;
        margin-left: 5px;
    }
    .danger-restaurant__table-text {
        flex-grow: 1;
        vertical-align: middle;
    }
</style>
